import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { EntryPage, SignInForm, SignInSSO, useTenant, NavBar as PublicNavBar } from '@emerald-works-nova/auth'
import { ContactUs } from '../../../components'
import { Grid } from '@mui/material'
import { routes } from '../../../routes'
import getForgotPasswordLink from '../../../helpers/getForgotPasswordLink'
import { useExternalApp } from '../../../hooks'
import { useSession } from '@emerald-works/react-auth'
import { useEvent } from '@emerald-works/react-event-bus-client'

export const SIGN_IN_PROVIDER_TYPE_STORAGE_KEY = 'login-provider-type'
export const SIGN_IN_USERNAME_PASSWORD = 'username_password'

const SignIn = () => {
  const tenant = useTenant()
  const location = useLocation()
  const { getMtUrl } = useExternalApp()
  const { isAuthenticated } = useSession()

  const redirectRoute = localStorage.getItem(location.state?.afterAuthLocationCookie) || localStorage.getItem('after-auth-location') || routes.home.path
  const samlProviders = tenant.loginProviders.filter(lp => lp.providerType === 'SAML' && lp.providerName !== 'internal')

  const homeLink = isAuthenticated ? routes.home.path : getMtUrl()
  const signupLink = getMtUrl('join')
  const forgotPasswordLink = useMemo(() => getForgotPasswordLink(tenant), [tenant])

  const isCanary = window.location.href.includes('beta.mindtools.com')

  const [loginInitiated] = useEvent([{ eventName: 'userLoginInitiated' }])

  const handleSubmitClick = (data) => {
    const { providerType = SIGN_IN_USERNAME_PASSWORD, providerName } = data
    localStorage.setItem(SIGN_IN_PROVIDER_TYPE_STORAGE_KEY, SIGN_IN_USERNAME_PASSWORD)
    loginInitiated.trigger({ identity: { providerType, providerName } })
  }

  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
    >
      <Grid item style={{ minHeight: 85, visibility: isCanary ? 'hidden' : 'visible' }}>
        {tenant?.tenantType === 'public' && (
          <PublicNavBar
            link={signupLink}
            captionText="Don't have an account?"
            buttonText='Sign up'
            homeLink={homeLink}
            homeText='Back to Home'
          />
        )}
      </Grid>
      <Grid item>
        <EntryPage
          title='Let’s sign in'
          subtitle='Enter your credentials below.'
          loginProviders={tenant?.loginProviders}
          showMtLogo
          content={(
            <SignInForm
              redirectRoute={redirectRoute}
              forgotPasswordRoute={forgotPasswordLink}
              forcePasswordChangeRoute={routes['change-password'].path}
              onSubmitClick={handleSubmitClick}
            />
          )}
          // social={tenant?.tenantType === 'public' && !samlProviders.length && (
          //   <div style={{ visibility: isCanary ? 'hidden' : 'visible' }}>
          //     <SignUpSocial
          //       text='Or'
          //       loginProviders={tenant?.loginProviders}
          //     />
          //   </div>
          // )}
          sso={tenant?.tenantType !== 'public' && !!samlProviders.length && (
            <SignInSSO
              loginProviders={samlProviders}
              onSubmitClick={handleSubmitClick}
            />
          )}
          entry={tenant?.tenantType !== 'public' && (
            <ContactUs />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default SignIn
