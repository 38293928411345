import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { Hub } from '@aws-amplify/core'
import { SIGN_IN_PROVIDER_TYPE_STORAGE_KEY } from '../pages/sign-in/components/sign-in'
const cookies = new Cookies()

const shouldRemove = (cookie) => !/cookieyes|cky-consent|Cognito/.test(cookie)

const removeCookies = () => Object.keys(cookies.getAll()).forEach(cookie => {
  if (shouldRemove(cookie)) {
    cookies.remove(cookie)
  }
})

const clearLocalStorage = () => {
  const whitelist = ['after-auth-location', 'skillBiteLanguage', 'defaultLanguage', 'jwplayer.captionLabel', SIGN_IN_PROVIDER_TYPE_STORAGE_KEY]
  const keys = Object.keys(localStorage)

  for (const key of keys) {
    if (!whitelist.includes(key)) {
      localStorage.removeItem(key)
    }
  }
}

const cleanData = () => {
  removeCookies()
  clearLocalStorage()
}

/**
 * Clean Prime cookies to prevent unexpected behaviours
 */
const useCleanCookies = () => {
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          cleanData()
          break
        case 'signUp':
          cleanData()
          break
        default:
      }
    })

    return unsubscribe
  }, [])
}

export default useCleanCookies
